export const ALL_BRANDS = '/admin/all-brands';
export const ALL_USERS = '/admin/all-users';
export const ALL_CATEGORIES = '/admin/all-categories';
export const SUB_CATEGORIES = '/admin/sub-categories'
export const ALL_TAGS = '/admin/all-tags';
export const LOGIN = '/admin';
export const ALL_PRODUCTS = '/admin/all-products';
export const ALL_TYPES = '/admin/all-types';
export const USER_DETAIL = '/admin/userDetail';
export const ADVERTISEMENTS = '/admin/advertisements';
export const REPORTED_ITEMS = '/admin/reported-items';
export const CONTACT_US='/admin/contact-us';
