export const AD_POST = '/adpost';
export const PROFILE = '/profile-info';
export const DASHBOARD = '/dashboard';
export const MY_ADS = '/myads';
export const SETTINGS = '/settings';
export const MY_ORDERS = '/my-orders';
export const VIEW_ALL_NOTIFICATION = '/all-notification';
export const VIEW_ALL_REVIEWS = '/all-reviews'
export const DETAIL_PAGE = `/:slug`; 
// export const DETAIL_PAGE = `/DetailPage`;
export const LIST_PAGE = '/ad_list';
export const BOOKMARK = '/bookMark';
export const SHOW_ALL_ADS = '/home';
export const MESSAGE = "/message";
export const USER_MESSAGE_LIST = "/user-meessage-list";
export const USER_FORM = '/user-form';
export const USER_LOGIN = '/login';
export const USER_REGISTER = '/profile';
export const USER_SIGNUP = '/signup';
export const USER_FORGOTTEN_PASSWORD = '/forgotten-password';
export const USER_RESET_PASSWORD = '/reset-password/:token';

// export const AD_POST = '/AdPost';
export const PUSHER_KEY='3e431aa16435f865e530'; 
